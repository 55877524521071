/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0ee93e3f-36fe-42f1-a047-965c3b6d76c9",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "XR": {
        "scenes": {
            "CommercientV136": {
                "sceneConfig": {
                    "sceneId": "1f889de482164985904e182c8fb17adb.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=1f889de482164985904e182c8fb17adb.scene"
                }
            },
            "CommercientV139": {
                "sceneConfig": {
                    "sceneId": "8106eee6849f4094b005da79f83d6921.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=8106eee6849f4094b005da79f83d6921.scene"
                }
            },
            "CommercientV141": {
                "sceneConfig": {
                    "sceneId": "8106eee6849f4094b005da79f83d6921.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=8106eee6849f4094b005da79f83d6921.scene"
                }
            },
            "CommercientV142": {
                "sceneConfig": {
                    "sceneId": "91e394f128684ca1b3edcbea45e91c58.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=91e394f128684ca1b3edcbea45e91c58.scene"
                }
            },
            "CommercientV143": {
                "sceneConfig": {
                    "sceneId": "8b9936e1de4043d08a7e2d9292d276c5.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=8b9936e1de4043d08a7e2d9292d276c5.scene"
                }
            },
            "CommercientV144": {
                "sceneConfig": {
                    "sceneId": "9912221b7b4041d8ac076b69e2f87dc6.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=9912221b7b4041d8ac076b69e2f87dc6.scene"
                }
            },
            "CommercientV145": {
                "sceneConfig": {
                    "sceneId": "dc6743d7e3cb4c60ad1d0b385f72daf3.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=dc6743d7e3cb4c60ad1d0b385f72daf3.scene"
                }
            },
            "CommercientV146": {
                "sceneConfig": {
                    "sceneId": "2a61ba76740d4440ab65d9b69415cd35.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=2a61ba76740d4440ab65d9b69415cd35.scene"
                }
            },
            "CommercientV147": {
                "sceneConfig": {
                    "sceneId": "041cd83f23c14b58ae17d554e350f989.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=041cd83f23c14b58ae17d554e350f989.scene"
                }
            },
            "CommercientV148": {
                "sceneConfig": {
                    "sceneId": "040bfcfaea804672aec5dbd5996d12d3.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=040bfcfaea804672aec5dbd5996d12d3.scene"
                }
            },
            "CommercientV149": {
                "sceneConfig": {
                    "sceneId": "4f69ab804ecd4659bef21993c8966979.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=4f69ab804ecd4659bef21993c8966979.scene"
                }
            },
            "CommercientV150": {
                "sceneConfig": {
                    "sceneId": "9d1c3a947e5b4779b000449097c45444.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=9d1c3a947e5b4779b000449097c45444.scene"
                }
            },
            "CommercientV151": {
                "sceneConfig": {
                    "sceneId": "f5f4ac5f48e84d9d87eda96431d39b04.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=f5f4ac5f48e84d9d87eda96431d39b04.scene"
                }
            },
            "CommercientV152": {
                "sceneConfig": {
                    "sceneId": "80d4f34425fe4483ae77b9faca8fe018.scene",
                    "region": "us-east-1",
                    "projectName": "Local Commercient Projects Arjun",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Local%20Commercient%20Projects%20Arjun/release/authTokens?sceneId=80d4f34425fe4483ae77b9faca8fe018.scene"
                }
            }
        }
    }
};


export default awsmobile;
